// TODO DA2-878: temporary hack to different environment config values until this is present in puppet

import { CoreConfig, defaultCoreConfig } from '@sigfig/digital-wealth-core';

const isIqa1 = () => window.location.origin === 'https://dw-pro-iqa1-bns.qa.sigfig.com';
const isIqa2 = () => window.location.origin === 'https://dw-pro-iqa2-bns.qa.sigfig.com';
const isSqa1 = () => window.location.origin === 'https://dw-pro-sqa1-bns.qa.sigfig.com';
const isSqa2 = () => window.location.origin === 'https://dw-pro-sqa2-bns.qa.sigfig.com';
const isSqa3 = () => window.location.origin === 'https://dw-pro-sqa3-bns.qa.sigfig.com';
const isSqa4 = () => window.location.origin === 'https://dw-pro-sqa4-bns.qa.sigfig.com';
const isSqa5 = () => window.location.origin === 'https://dw-pro-sqa5-bns.qa.sigfig.com';
const isSqa6 = () => window.location.origin === 'https://dw-pro-sqa6-bns.qa.sigfig.com';
const isSqa7 = () => window.location.origin === 'https://dw-pro-sqa7-bns.qa.sigfig.com';
const isSqa8 = () => window.location.origin === 'https://dw-pro-sqa8-bns.qa.sigfig.com';
export const isSqa9 = () => window.location.origin === 'https://dw-pro-sqa9-bns.qa.sigfig.com';
const isStg = () => window.location.origin === 'https://dw-pro-stg-bns.qa.sigfig.com';
export const isProd = () => window.location.origin === 'https://smart-investor.scotiabank.com';

const getConfigValue = ({
  iqa1,
  iqa2,
  sqa1,
  sqa2,
  sqa3,
  sqa4,
  sqa5,
  sqa6,
  sqa7,
  sqa8,
  sqa9,
  stg,
  prod,
  _,
}: {
  _: string;
  iqa1?: string;
  iqa2?: string;
  prod?: string;
  sqa1?: string;
  sqa2?: string;
  sqa3?: string;
  sqa4?: string;
  sqa5?: string;
  sqa6?: string;
  sqa7?: string;
  sqa8?: string;
  sqa9?: string;
  stg?: string;
}) => {
  if (isIqa1() && iqa1) {
    return iqa1;
  }
  if (isIqa2() && iqa2) {
    return iqa2;
  }
  if (isSqa1() && sqa1) {
    return sqa1;
  }
  if (isSqa2() && sqa2) {
    return sqa2;
  }
  if (isSqa3() && sqa3) {
    return sqa3;
  }
  if (isSqa4() && sqa4) {
    return sqa4;
  }
  if (isSqa5() && sqa5) {
    return sqa5;
  }
  if (isSqa6() && sqa6) {
    return sqa6;
  }
  if (isSqa7() && sqa7) {
    return sqa7;
  }
  if (isSqa8() && sqa8) {
    return sqa8;
  }
  if (isSqa9() && sqa9) {
    return sqa9;
  }
  if (isStg() && stg) {
    return stg;
  }
  if (isProd() && prod) {
    return prod;
  }
  return _;
};

export default {
  contentstack: {
    environment: getConfigValue({
      sqa1: 'bns_sqa1_sqa2',
      sqa2: 'bns_sqa1_sqa2',
      sqa3: 'bns_sqa3_sqa4',
      sqa4: 'bns_sqa3_sqa4',
      sqa5: 'bns_sqa5_sqa6',
      sqa6: 'bns_sqa5_sqa6',
      sqa7: 'bns_sqa7_sqa8',
      sqa8: 'bns_sqa7_sqa8',
      sqa9: 'bns_sqa9',
      stg: 'bns_stg',
      prod: 'bns_prod',
      _: 'dev',
    }),
    deliveryToken: getConfigValue({
      sqa1: 'cse012dad342af1d9460fdfae8',
      sqa2: 'cse012dad342af1d9460fdfae8',
      sqa3: 'cs81847d9eb8b7a2a24dc0cda4',
      sqa4: 'cs81847d9eb8b7a2a24dc0cda4',
      sqa5: 'csfb74bfb1dae540935663b17c ',
      sqa6: 'csfb74bfb1dae540935663b17c ',
      sqa7: 'csa3f7ea38c0fc20aca73e44f9',
      sqa8: 'csa3f7ea38c0fc20aca73e44f9',
      sqa9: 'csabaa8c59f4be01ad13f8f173',
      stg: 'cs7be8ff36543972f2ee5744e3',
      prod: 'cs046aec647de153015350df54',
      _: 'csaffbef3139b4b24aee2cc24c',
    }),
  },
  auth0: {
    clientId: getConfigValue({
      iqa2: '4vCMHAp3TxdXleVfnpm1GAtvirkRE2su',
      sqa1: 'BrKNEAlK1Miuoh7tKRK7IjHSdFh7eVD6',
      sqa2: 'GizNID7TMBKajasFaRYb21z4K4dC7AF1',
      sqa3: 'JJ8d2L7FJPXQGiwA6NUpYu1awT1byEXW',
      sqa4: 'Rk7YVWKEefMlbe169xcLsEpiNc8cWY25',
      sqa5: 'aYEL4SlYF8YF1CJd8DNzKBCqDfWV4D7s',
      sqa6: 'Xw28VE6Eoi9rcjOf9MbHcaP1sAo1HUHM',
      sqa7: 'r7qOlAwziZF7rdfnV2vGkttOtx63GF6l',
      sqa8: 'BWwuPwCUxOaJYhOEtq5PN5xS9madY26i',
      sqa9: 'vJWiIe0pAWPpp7zPUdUTQzuId7Io84fv',
      stg: '5WqldBOHYo3SDlLxHJS4Xh8W2uHWH9Yf',
      prod: 'vgp1vV5FLAccW6FIGvUr4zYsTb2gO9sj',
      _: 'GgErs6wqEYfjvMBUNzpb7N0NJDeBkbzN',
    }),
    domain: getConfigValue({
      iqa2: 'bns-iqa2.us.auth0.com',
      sqa1: 'bns-sqa.us.auth0.com',
      sqa2: 'bns-sq2.us.auth0.com',
      sqa3: 'bns-sqa3.us.auth0.com',
      sqa4: 'bns-sqa4.us.auth0.com',
      sqa5: 'bns-sqa5.us.auth0.com',
      sqa6: 'bns-sqa6.us.auth0.com',
      sqa7: 'bns-sqa7.us.auth0.com',
      sqa8: 'bns-sqa8.us.auth0.com',
      sqa9: 'bns-sqa9.us.auth0.com',
      stg: 'bns-stg.us.auth0.com',
      prod: 'bns-prod.us.auth0.com',
      _: 'bns-iqa.us.auth0.com',
    }),
    audienceEnv: getConfigValue({
      iqa1: 'iqa1',
      iqa2: 'iqa2',
      sqa1: 'sqa1',
      sqa2: 'sqa2',
      sqa3: 'sqa3',
      sqa4: 'sqa4',
      sqa5: 'sqa5',
      sqa6: 'sqa6',
      sqa7: 'sqa7',
      sqa8: 'sqa8',
      sqa9: 'sqa9',
      stg: 'stg',
      prod: 'prod',
      _: 'iqa1',
    }),
  },
  deepLink: {
    key: getConfigValue({
      sqa1:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgN/fCsgVCBNtOhzMpYY7\n' +
        'mj0nVwnVFPPaIC5+1In5loR5TkBAFhDhMUPVM6qAnb0RGra2+l3AHWRjBLv3Imyh\n' +
        'zmETdb24PzMXB/Xc5vqxitStTnOpOoOIQA6zkHLWOzbIOnSdHUTzyG8OXRJX2iBC\n' +
        'o9iK0SVpUO6YHfb46IDw6KWtbCq4nHNkpSnd011fc7a3ktL60uzs7S8NVuY44dZ7\n' +
        'QO6PIsW0adxo1foIFPwhx67uHV1W+KwfnVhml9Fc6PqgSsZ3s19mjXdayrHRNcPo\n' +
        'sXNhiiWjKQIvNNNUO7VDLLnTLHEb+gHqQVCpEHuMTqAN20vI4mXEk9R0c5wBxT+e\n' +
        'ewIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa2:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy32hnk1OwIVzsrQN/DqD\n' +
        'VTbzj7wuvvcRVGTjU4v7PINZ6oQUyCXcqll2SCdiahS9Zyjk9MPZjvHc+3OUILyH\n' +
        'HlUAVY2ktkbbzlMK05hD2WyDpLoWYLJeG2YWIXEKUZPWahCr0mJVFKNCKakdr6X5\n' +
        'uPH8XPcrOMhLni16KVSfFJQYTgjvNOkkveMTY9JpULrycsphjFCl9hjIxSjz/JmU\n' +
        '+ChMAL6PrudZ8enLWrHreJhKM7hIZSTRxtfDrBCtc1V+zJE1DLsmTtdb958SfMH6\n' +
        'eVX2jEyxTov4OI1m4JJAVYgkmllKIjeSVnqFwZOEtUe5JLjkE1IA8OPMa8m85uPn\n' +
        'IQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa3:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgN/fCsgVCBNtOhzMpYY7\n' +
        'mj0nVwnVFPPaIC5+1In5loR5TkBAFhDhMUPVM6qAnb0RGra2+l3AHWRjBLv3Imyh\n' +
        'zmETdb24PzMXB/Xc5vqxitStTnOpOoOIQA6zkHLWOzbIOnSdHUTzyG8OXRJX2iBC\n' +
        'o9iK0SVpUO6YHfb46IDw6KWtbCq4nHNkpSnd011fc7a3ktL60uzs7S8NVuY44dZ7\n' +
        'QO6PIsW0adxo1foIFPwhx67uHV1W+KwfnVhml9Fc6PqgSsZ3s19mjXdayrHRNcPo\n' +
        'sXNhiiWjKQIvNNNUO7VDLLnTLHEb+gHqQVCpEHuMTqAN20vI4mXEk9R0c5wBxT+e\n' +
        'ewIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa4:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy32hnk1OwIVzsrQN/DqD\n' +
        'VTbzj7wuvvcRVGTjU4v7PINZ6oQUyCXcqll2SCdiahS9Zyjk9MPZjvHc+3OUILyH\n' +
        'HlUAVY2ktkbbzlMK05hD2WyDpLoWYLJeG2YWIXEKUZPWahCr0mJVFKNCKakdr6X5\n' +
        'uPH8XPcrOMhLni16KVSfFJQYTgjvNOkkveMTY9JpULrycsphjFCl9hjIxSjz/JmU\n' +
        '+ChMAL6PrudZ8enLWrHreJhKM7hIZSTRxtfDrBCtc1V+zJE1DLsmTtdb958SfMH6\n' +
        'eVX2jEyxTov4OI1m4JJAVYgkmllKIjeSVnqFwZOEtUe5JLjkE1IA8OPMa8m85uPn\n' +
        'IQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa5:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgN/fCsgVCBNtOhzMpYY7\n' +
        'mj0nVwnVFPPaIC5+1In5loR5TkBAFhDhMUPVM6qAnb0RGra2+l3AHWRjBLv3Imyh\n' +
        'zmETdb24PzMXB/Xc5vqxitStTnOpOoOIQA6zkHLWOzbIOnSdHUTzyG8OXRJX2iBC\n' +
        'o9iK0SVpUO6YHfb46IDw6KWtbCq4nHNkpSnd011fc7a3ktL60uzs7S8NVuY44dZ7\n' +
        'QO6PIsW0adxo1foIFPwhx67uHV1W+KwfnVhml9Fc6PqgSsZ3s19mjXdayrHRNcPo\n' +
        'sXNhiiWjKQIvNNNUO7VDLLnTLHEb+gHqQVCpEHuMTqAN20vI4mXEk9R0c5wBxT+e\n' +
        'ewIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa6:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy32hnk1OwIVzsrQN/DqD\n' +
        'VTbzj7wuvvcRVGTjU4v7PINZ6oQUyCXcqll2SCdiahS9Zyjk9MPZjvHc+3OUILyH\n' +
        'HlUAVY2ktkbbzlMK05hD2WyDpLoWYLJeG2YWIXEKUZPWahCr0mJVFKNCKakdr6X5\n' +
        'uPH8XPcrOMhLni16KVSfFJQYTgjvNOkkveMTY9JpULrycsphjFCl9hjIxSjz/JmU\n' +
        '+ChMAL6PrudZ8enLWrHreJhKM7hIZSTRxtfDrBCtc1V+zJE1DLsmTtdb958SfMH6\n' +
        'eVX2jEyxTov4OI1m4JJAVYgkmllKIjeSVnqFwZOEtUe5JLjkE1IA8OPMa8m85uPn\n' +
        'IQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa7:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgN/fCsgVCBNtOhzMpYY7\n' +
        'mj0nVwnVFPPaIC5+1In5loR5TkBAFhDhMUPVM6qAnb0RGra2+l3AHWRjBLv3Imyh\n' +
        'zmETdb24PzMXB/Xc5vqxitStTnOpOoOIQA6zkHLWOzbIOnSdHUTzyG8OXRJX2iBC\n' +
        'o9iK0SVpUO6YHfb46IDw6KWtbCq4nHNkpSnd011fc7a3ktL60uzs7S8NVuY44dZ7\n' +
        'QO6PIsW0adxo1foIFPwhx67uHV1W+KwfnVhml9Fc6PqgSsZ3s19mjXdayrHRNcPo\n' +
        'sXNhiiWjKQIvNNNUO7VDLLnTLHEb+gHqQVCpEHuMTqAN20vI4mXEk9R0c5wBxT+e\n' +
        'ewIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa8:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy32hnk1OwIVzsrQN/DqD\n' +
        'VTbzj7wuvvcRVGTjU4v7PINZ6oQUyCXcqll2SCdiahS9Zyjk9MPZjvHc+3OUILyH\n' +
        'HlUAVY2ktkbbzlMK05hD2WyDpLoWYLJeG2YWIXEKUZPWahCr0mJVFKNCKakdr6X5\n' +
        'uPH8XPcrOMhLni16KVSfFJQYTgjvNOkkveMTY9JpULrycsphjFCl9hjIxSjz/JmU\n' +
        '+ChMAL6PrudZ8enLWrHreJhKM7hIZSTRxtfDrBCtc1V+zJE1DLsmTtdb958SfMH6\n' +
        'eVX2jEyxTov4OI1m4JJAVYgkmllKIjeSVnqFwZOEtUe5JLjkE1IA8OPMa8m85uPn\n' +
        'IQIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      sqa9:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmj+LlVq/dTiYOVA6GO/f\n' +
        'mQNQYXWya3+BaS0CGbYc91MllMxI8ePP+3rINij63qpxLIE6Bf7su+wfnzeB6+5R\n' +
        '9JxrtsyFcC2EP28nfJwKPZS3otnAsdCfXMRsOGw412Yo8AyrQaNWuBDY9l2n2kSK\n' +
        'JgERhoN0Kl2FQKMGnjCjYZOgMl8YciCCESB+UwJ7EfkxjH235uv24xE9IXy445uL\n' +
        'YxrAazQQG62OG1tUPkW6pl8sbAM2Ruv12JmtGVxpoWT50nfGMtvNMHq/unczEgzn\n' +
        'vdkfJwXK0YrgnZvb6oxEj7XYzyE2GrH08IjUneNSJOeUyR0U+5E9fflztmQOfj9t\n' +
        'DwIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      stg:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmj+LlVq/dTiYOVA6GO/f\n' +
        'mQNQYXWya3+BaS0CGbYc91MllMxI8ePP+3rINij63qpxLIE6Bf7su+wfnzeB6+5R\n' +
        '9JxrtsyFcC2EP28nfJwKPZS3otnAsdCfXMRsOGw412Yo8AyrQaNWuBDY9l2n2kSK\n' +
        'JgERhoN0Kl2FQKMGnjCjYZOgMl8YciCCESB+UwJ7EfkxjH235uv24xE9IXy445uL\n' +
        'YxrAazQQG62OG1tUPkW6pl8sbAM2Ruv12JmtGVxpoWT50nfGMtvNMHq/unczEgzn\n' +
        'vdkfJwXK0YrgnZvb6oxEj7XYzyE2GrH08IjUneNSJOeUyR0U+5E9fflztmQOfj9t\n' +
        'DwIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      prod:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjQpR+ctCQjr4Fd7O6ky3\n' +
        'MNI4XLyOcyYxI1hi/k93YhZCkXuH/DveV0/iVh1eDGJZ4r4CWTtwhnv1pLhpg4c1\n' +
        'hVHhdIXqkcRlL1V/J6weld9F0s9INZXZFXNpMSDnUjYKWuedUontcsoSfl8fcsPq\n' +
        '215bWGLgjtRhyRYdLhKo49m0oi+r3rd6Cjx5C7B0f7xl7FzX+8ByINGNA6BshKvN\n' +
        'aX4h62q2gkRwqD0Q4D+YAiXw/vaErSJHVTlw8WgJImdE2yTbRsoEvkwQ4DN+iUlZ\n' +
        'tdcbBED/Jj31/wDcU3ifeSxb1ZxttshBCqmOC1fC+LJV3pyhHVLi8W8YM2p9QJ7f\n' +
        'rwIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
      _:
        '-----BEGIN PUBLIC KEY-----\n' +
        'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgN/fCsgVCBNtOhzMpYY7\n' +
        'mj0nVwnVFPPaIC5+1In5loR5TkBAFhDhMUPVM6qAnb0RGra2+l3AHWRjBLv3Imyh\n' +
        'zmETdb24PzMXB/Xc5vqxitStTnOpOoOIQA6zkHLWOzbIOnSdHUTzyG8OXRJX2iBC\n' +
        'o9iK0SVpUO6YHfb46IDw6KWtbCq4nHNkpSnd011fc7a3ktL60uzs7S8NVuY44dZ7\n' +
        'QO6PIsW0adxo1foIFPwhx67uHV1W+KwfnVhml9Fc6PqgSsZ3s19mjXdayrHRNcPo\n' +
        'sXNhiiWjKQIvNNNUO7VDLLnTLHEb+gHqQVCpEHuMTqAN20vI4mXEk9R0c5wBxT+e\n' +
        'ewIDAQAB\n' +
        '-----END PUBLIC KEY-----\n',
    }),
  },
  sentry: {
    dsn: 'https://e3c10aa70fd74190ae1427e4e483d7c5@o14749.ingest.sentry.io/5961363',
    environment: getConfigValue({
      iqa1: 'iqa1',
      iqa2: 'iqa2',
      sqa1: 'sqa1',
      sqa2: 'sqa2',
      sqa3: 'sqa3',
      sqa4: 'sqa4',
      sqa5: 'sqa5',
      sqa6: 'sqa6',
      sqa7: 'sqa7',
      sqa8: 'sqa8',
      sqa9: 'sqa9',
      stg: 'staging',
      prod: 'production',
      _: 'dev',
    }),
  },
  newRelic: {
    licenseKey: '3288c5f750',
    applicationId: getConfigValue({
      iqa1: '718275058',
      iqa2: '718275058',
      sqa1: '718289527',
      sqa2: '718289528',
      sqa3: '718289527',
      sqa4: '718289528',
      sqa5: '718289527',
      sqa6: '718289528',
      sqa7: '718289527',
      sqa8: '718289528',
      sqa9: '718276889',
      stg: '718276889',
      prod: '718275043',
      _: '718275058',
    }),
  },
  goals: {
    partnerConfigId: getConfigValue({
      iqa1: '5f073c15-719a-4327-b300-b6eb70f00a7a',
      iqa2: '6dcaa689-c2c3-42a5-9f34-45ff9fb7d475',
      sqa1: '15f9d4ec-d450-4b85-97c2-e416b2aec9d2',
      sqa2: '5c9cd147-afec-4a8f-b86e-c28474abb6bc',
      sqa3: 'a229ee93-06db-49fc-856c-57290614b454',
      sqa4: 'a64e4790-ebc5-4304-8bfa-90f1a5d75ac7',
      sqa5: '5c51205a-7307-477d-bd1b-8ceb195a271f',
      sqa6: '00376e71-5b62-417d-8d9e-4e8e5a6e3ae0',
      sqa7: 'c4e2fc68-4493-4e80-816d-8e12bdd299c9',
      sqa8: '3bc7dc14-62bd-4313-addc-5f5f9754ae33',
      sqa9: 'd8839c15-959f-48b9-92ab-80a7c4d80d1b',
      stg: '79f9e4ea-c43d-408c-ac46-c978a1b7aafa',
      prod: 'dde72493-4c59-4fff-8808-4b76f614b423',
      _: '5f073c15-719a-4327-b300-b6eb70f00a7a',
    }),
    url: getConfigValue({
      iqa1: 'https://47uk4u6fwrbudliyav2m3lyhwa.appsync-api.us-east-2.amazonaws.com/graphql',
      iqa2: 'https://xtizbt263ffe5deogjge47kouu.appsync-api.us-east-1.amazonaws.com/graphql',
      sqa1: 'https://rsfblhbefzambnvg5rjos2m5re.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa2: 'https://336vqqqcdncdpfdnytqafjvvta.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa3: 'https://qexq2rgphbfuzoybgqhbf2jbk4.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa4: 'https://frbjsyr4fjcqxdtjjsbhtjkfg4.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa5: 'https://tkk33xz5nbf7tksuw5nel2z5uu.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa6: 'https://lrrkpqia4nfxzdc5v52hjmij44.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa7: 'https://xi2sodvo3zbvnpemow32m5tecq.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa8: 'https://xfyf2w6365fudl24maqfuo7ac4.appsync-api.us-east-2.amazonaws.com/graphql',
      sqa9: 'https://t5qr644uprfjxawjxxbnjaryse.appsync-api.us-east-2.amazonaws.com/graphql',
      stg: 'https://zexdarsvuzbghdkajju6ziws4e.appsync-api.us-east-2.amazonaws.com/graphql',
      prod: 'https://tzzs37qkjnh5vdfq2o4ynk6may.appsync-api.us-east-2.amazonaws.com/graphql',
      _: 'https://47uk4u6fwrbudliyav2m3lyhwa.appsync-api.us-east-2.amazonaws.com/graphql',
    }),
  },
  posthog: {
    token: getConfigValue({
      prod: 'phc_wAAwlh02MecmZZxrg4fJ7BbhXCYv4HqoQxhcArpGYAO',
      _: 'phc_EpiD1VgtpRhk7u2wq303Isj8ckaylpXEFfJJOJN8BOR',
    }),
  },
};

export const coreConfigs: CoreConfig = {
  ...defaultCoreConfig,
  components: {
    ...defaultCoreConfig.components,
    sfGoals: {
      goalSelection: { allowSkipGoalCreationDuringOnboarding: false, isGoalDetailsModalEnabled: true },
    },
    sfMadlibs: { allowDisablingAgeField: true },
    sfQuestionnaire: { allowDisablingAgeField: true },
  },
  featureFlags: {
    ...defaultCoreConfig.featureFlags,
    goals: {
      disableBankAccountType: true,
      disableNewProjectionCalculator: true,
      enableGoalsFeature: true,
      enableBranchIdForGoalCreation: true,
    },
  },
};
